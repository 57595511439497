<template>
  <form
    ref="form"
    @submit.prevent="next"
  >
    <c-title>Antragsformular</c-title>

    <p class="mt-8 mb-8">
      Alle Felder außer den explizit ausgenommenen sind Pflichtfelder.
    </p>
    <c-form-text
      v-model="fields.name"
      :errors="errors.name"
      class="mb-8"
      label="Name Kultureinrichtung/Künstler*in/Künstler*innengruppe"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.legal_form"
      :errors="errors.legal_form"
      class="mb-8"
      label="Rechtsform (gilt nur für Kultureinrichtungen)"
      autocomplete="honorific-suffix"
    />
    <c-form-text
      v-model="fields.contact_person"
      :errors="errors.contact_person"
      class="mb-8"
      label="Ansprechpartner*in"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.address"
      :errors="errors.address"
      class="mb-8"
      label="Straße und Hausnummer"
      autocomplete="street-address"
      maxlength="100"
      required
    />
    <c-form-columns :columns="[1, 5]">
      <c-form-text
        v-model="fields.postal_code"
        :errors="errors.postal_code"
        class="mb-8"
        label="Postleitzahl"
        autocomplete="postal-code"
        inputmode="numeric"
        pattern="[0-9]*"
        minlength="5"
        maxlength="5"
        required
      />
      <c-form-text
        v-model="fields.city"
        :errors="errors.city"
        class="mb-8"
        label="Ort"
        autocomplete="address-level2"
        maxlength="100"
        required
      />
    </c-form-columns>
    <c-form-text
      v-model="fields.contact_number"
      :errors="errors.contact_number"
      class="mb-8"
      label="Telefonnummer"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.email"
      :errors="errors.email"
      class="mb-8"
      label="E-Mail-Adresse"
      type="email"
      autocomplete="email"
      required
    />
    <c-form-text
      v-model="fields.website"
      :errors="errors.website"
      class="mb-8"
      label="Website/Internetauftritt (optional)"
      autocomplete="honorific-suffix"
      type="url"
      placeholder="https://example.com"
    />
    <c-form-text
      v-model="fields.project_title"
      :errors="errors.project_title"
      class="mb-8"
      label="Titel des Vorhabens"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      v-model="fields.message_text"
      :errors="errors.message_text"
      class="mb-8"
      label="Beschreibung des Vorhabens inkl. Informationen zu den Projektbeteiligten"
      :help="`max. 2.500 Zeichen; Links zu Internetauftritten der Projektbeteiligten können in dieses Feld eingetragen werden.`"
      autocomplete="honorific-suffix"
      required
      tag="textarea"
      maxlength="2500"
      rows="30"
    />
    <c-form-text
      v-model="fields.start_period"
      :errors="errors.start_period"
      class="mb-8"
      label="Beginn, Zeitraum"
      required
    />
    <c-form-text
      v-model="fields.application_amount"
      :errors="errors.application_amount"
      class="mb-8"
      label="Antragssumme"
      autocomplete="honorific-suffix"
      required
    />
    <c-form-text
      :errors="errors.cost_plan_file"
      class="mt-5 mb-8"
      type="file"
      :file="fields.cost_plan_file"
      label="Kostenplan"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx,.xls,.xlsx"
      required
      @change="fields.cost_plan_file = $event"
    />
    <c-form-text
      :errors="errors.vitae_project_participants"
      class="mt-5 mb-8"
      type="file"
      :file="fields.vitae_project_participants"
      label="Vitae Projektbeteiligte (optional)"
      :help="`Erlaubte Dateiendungen: PDF, DOC, DOCX, XLS und XLSX. Maximale Dateigröße: 5 MB.`"
      accept=".pdf,.doc,.docx"
      @change="fields.vitae_project_participants = $event"
    />
    <c-button>
      Nächster Schritt
    </c-button>
  </form>
</template>

<script>
import BRequestStep from './BRequestStep.vue'

export default {
  name: 'VRequestStep0',

  extends: BRequestStep,

  data () {
    return {
      fields: {
        name: '',
        legal_form: '',
        contact_person: '',
        address: '',
        contact_number: '',
        email: '',
        website: '',
        project_title: '',
        message_text: '',
        start_period: '',
        application_amount: '',
        cost_plan_file: '',
        vitae_project_participants: ''
      }
    }
  },

  computed: {
    step: () => 0,
    id: () => 'reportData'
  }
}
</script>
